import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Heading, Text } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import NotFoundImg from "../../404.svg";
import "./styles.css";
export function NotFoundPage() {
  const { colorMode } = useColorMode();

  return (
    <>
      <Box className="notfound-container">
        <Box className="notfound-content">
          <Image
            fallbackSrc={NotFoundImg}
            src={NotFoundImg}
            alt="Error 404 not found Illustration"
          />
          <Box marginY={4}>
            <Heading textAlign="center">Page not Found.</Heading>

            <Box textAlign="center" marginTop={4}>
              <Text mb="4">It&apos;s Okay!</Text>
              <Link to="/">
                <Button
                  backgroundColor={
                    colorMode === "light" ? "gray.300" : "teal.500"
                  }
                >
                  Let&apos;s Head Back
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
