import { Box, Link } from "@chakra-ui/layout";
import React from "react";
import "../styles.css";
// import { Link as LinkRoute } from "react-router-dom";

export default function Contact() {
  return (
    <Box className="contact" backgroundColor="black" pb="100px">
      <Box textAlign="center" color="white" pt="0px">
        <Box fontWeight={500} fontSize={{ base: "20px", lg: "30px" }}>
          But I'd like to hear about what you're working on!
        </Box>
        <Box mt="20px">
          <Link
            color="#f9004d"
            target="_new"
            href="mailto:dcsan@rik.ai"
            textDecoration="none !important"
          >
            Contact me at dcsan@rik.ai
          </Link>
        </Box>

        {/*
        <Box mt="20px">
          <LinkRoute
            to="/projects"
            style={{
              color: "#f9004d",
            }}
          >
            Full list of project
          </LinkRoute>
        </Box> */}

      </Box>
    </Box>
  );
}
