import PublicRoute from "./PublicRoute";
import React from "react";
import { useCallback, useMemo } from "react";
import { GENERAL_ROUTES } from "./index";
import { Switch, Route } from "react-router-dom";
import { NotFoundPage } from "../pages/NotFoundPage";

export const RenderRoutes = () => {
  const loggedRoutes = useMemo(() => {
    return [...GENERAL_ROUTES];
  }, []);

  const renderLoggedRoutes = useCallback(() => {
    return loggedRoutes.map((route) => {
      return (
        <PublicRoute
          key={route.path}
          path={route.path}
          name={route.name}
          exact={route.exact}
          layout={route.layout}
          component={route.component}
        />
      );
    });
  }, [loggedRoutes]);

  return (
    <Switch>
      {renderLoggedRoutes()}
      <Route component={NotFoundPage} />
    </Switch>
  );
};
