import { ChakraProvider, theme } from "@chakra-ui/react";
import * as React from "react";
// import { HashRouter } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { RenderRoutes } from "./routes/RenderRoutes";
import "./index.css";
export const App = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <RenderRoutes />
    </BrowserRouter>
  </ChakraProvider>
);
