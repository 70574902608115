import { Link } from "@chakra-ui/react";
import React from "react";
import {
  FaEnvelope,
  FaGlobeAmericas,
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaGithub />, link: "https://github.com/dcsan" },
  { Social: <FaEnvelope />, link: "mailto:dcsan@rik.ai" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/dcsan" },
  { Social: <FaGlobeAmericas />, link: "https://dc.rik.ai" },
  { Social: <FaTwitter />, link: "https://twitter.com/dcsan" },
];
export default function Socials() {
  return (
    <>
      {SocialShare.map((social, index) => (
        <Link key={index} href={social.link} target="_blank">
          {social.Social}
        </Link>
      ))}
    </>
  );
}
