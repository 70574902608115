import { Box, Center } from "@chakra-ui/layout";
import React, { useMemo } from "react";
import {
  AiFillAndroid,
  // AiFillFacebook,
  AiFillWechat,
  AiOutlineDatabase,
  AiOutlineLaptop,
  AiOutlineMedium,
} from "react-icons/ai";
// import { FaBitcoin } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "../styles.css";

export default function Skills() {
  const history = useHistory();

  const Boxskills = useMemo(
    () => [

      {
        name: "Projects",
        icon: <AiOutlineDatabase />,
        des: "All kinds of projects",
        url: "/projects",
      },

      {
        name: "Generative AI",
        icon: <AiFillWechat />,
        des: "Gen AI for bots, language and images",
        url: "/projects?tag=GenAI",
      },

      {
        name: "ChatBots",
        icon: <AiFillWechat />,
        des: "chatbots for Discord, Telegram and WeChat",
        url: "/projects?tag=chatbots",
      },

      {
        name: "Games",
        icon: <AiFillAndroid />,
        des: "Developed games for multiple platforms",
        url: "/projects?tag=games",
      },

      // {
      //   name: "Social networks",
      //   icon: <AiFillFacebook />,
      //   des: "Designed and operated Social Networks",
      //   url: "/projects?tag=sns",
      // },

      // {
      //   name: "Bus Dev",
      //   icon: <AiOutlineDatabase />,
      //   des: "In startups, 'sales fixes everything'",
      //   url: "/projects/bizdev",
      // },

      {
        name: "ML platforms",
        icon: <AiOutlineMedium />,
        des: "NLP and AI / ML expertise",
        url: "/projects?tag=nlp",
      },

      {
        name: "NFT art",
        icon: <AiOutlineLaptop />,
        des: "Creative coding NFT projects",
        url: "projects?tag=NFT",
      },

      {
        name: "APIs and SDKs",
        icon: <AiOutlineLaptop />,
        des: "Developed bots and SDKs for Google",
        url: "/projects?tag=sdk",
      },


    ],
    []
  );

  return (
    <Box className="skills" backgroundColor="black" pb="100px">
      <Box maxWidth="1440px" margin="0 auto" px={4}>
        <Box
          textAlign="center"
          fontWeight={300}
          fontSize={{ base: "30px", lg: "50px" }}
          color="white"
          pt="80px"
        >
          Explore Projects
        </Box>
        <Box
          className="skills-container"
          display="flex"
          color="red"
          flexWrap="wrap"
          mt="50px"
        >
          {Boxskills.map((item, index) => (
            <Box
              key={index}
              className="item item1"
              w={{ base: "100%", md: "50%", lg: "25%" }}
              mt={{ base: "10px", lg: "50px" }}
              onClick={() => history.push(item.url)}
              transition="all .2s ease-in-out"
              _hover={{ transform: "scale(1.1)" }}
            >
              <Center h="250px">
                <Box
                  backgroundColor="#191919"
                  borderRadius="10px"
                  w="80%"
                  h="220px"
                  pl="30px"
                  pr="30px"
                  color="white"
                  _hover={{
                    color: "white !important",
                    cursor: "pointer",
                    svg: { color: "white" },
                    div: { color: "white" },
                    backgroundImage:
                      "linear-gradient(to right, #f61b10 , #ef0963)",
                  }}
                >
                  <Center h="200px">
                    <Box>
                      <Box color="#f9004d">{item.icon}</Box>
                      <Box>{item.name}</Box>
                      <Box color="rgba(198,201,216,0.75)">{item.des}</Box>
                    </Box>
                  </Center>
                </Box>
              </Center>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
