import '../styles.css';

import {
  Box,
  Center,
  // useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import React from "react";

interface Project {
  title?: string;
  client?: string;
  cover_image?: string;
  path: string;
}



interface ProjectCardItemProps {
  project: Project;
}

export default function ProjectCardItem({ project }: ProjectCardItemProps) {
  const history = useHistory();

  const { cover_image, title, client, path } = project;
  return (
    <Center
      cursor="pointer"
      marginBottom="20px"
      onClick={() => history.push(path)}
      borderRadius="10px !important"
    >
      <Box
        className="project-card"
        w={"full"}
        pos={"relative"}
        zIndex={1}
        transition="all .2s ease-in-out"
        _hover={{ transform: "scale(1.05)" }}
      >
        <Box pos={"relative"}>
          <Image width="100%" objectFit={"contain"} src={cover_image} />
        </Box>
        <Stack p="8px" align={"center"} bg="black" color="white">
          <Heading fontSize={"2xl"} fontFamily={"body"} fontWeight={500}>
            {title}
          </Heading>
          <Stack direction={"row"} align={"center"}>
            <Text>{client}</Text>
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
}
