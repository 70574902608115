import { Box } from "@chakra-ui/react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import React from "react";


interface GeneralLayoutProps {
  children: Element;
}

const GeneralLayout = ({ children }: GeneralLayoutProps) => {
  return (
    <Box>
      <Header />
      {/* @ts-ignore */}
      <Box minHeight="100vh">{children}</Box>
      <Footer />
    </Box>
  );
};

export default GeneralLayout;
