import { Box, Center } from "@chakra-ui/layout";
import React from "react";
// import TextLoop from "react-text-loop";
import "../styles.css";
import "./index.css";
import Particles from "react-tsparticles";

export default function Welcome() {

  // const skills = useMemo(
  //   () => [
  //     "Mobile/Social Game designer",
  //     "Social network producer",
  //     "Chatbot developer",
  //     "Serial Entrepreneur",
  //     "ML/AI Engineer",
  //     "API and SDK architect",
  //     "Crypto game designer",
  //     "NFT art Creative Coder",
  //     "NLP platform developer"
  //   ],
  //   []
  // );

  return (
    <Box backgroundColor="black" className="home-page-container">
      <Particles
        id="tsparticles"
        options={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              images: [],
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 3,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          detectRetina: true,
        }}
      />

      <Box maxWidth="1440px" margin="0 auto" px={4}>

        <Box className="welcome-to-my-world">

          <Box w={{ base: "100%", lg: "600px" }}>
            <Center className="container" height="250px">
              <Box ml={{ base: "25px !important", sm: "0px" }}>
                <Box as="span" color="rgba(198,201,216,0.75)">
                  <code>$ hello world </code>
                </Box>
                <Box
                  className="title"
                  fontSize={{ base: "30px", lg: "50px" }}
                  fontWeight={800}
                >
                  <Box color="white">I’m David 'DC' Collier</Box>
                  <Box color="#f9004d">

                  </Box>
                </Box>
                <Box color="white" fontSize="20px" fontWeight={610}>
                  Perpetual Maker Machine!
                </Box>
              </Box>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}


// <TextLoop>
//   {skills.map((item, index) => (
//     <Box
//       as="span"
//       key={index}
//       fontSize={{ base: "20px", lg: "40px" }}
//     >
//       {item}
//     </Box>
//   ))}
// </TextLoop>
